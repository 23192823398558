<template>
  <div id="sla-builder" class="field">
    <BaseScrollbar>
      <FormFieldLabel
        label="SLA Response Configurations"
        class="action-header"
      />

      <SingleChoiceField
        v-model="value.settings.slaType"
        :options="slaTypes"
        :options-per-line="2"
        class="col q-mb-md"
      />
      <template v-if="value.settings.slaType === 'STANDARD'">
        <table>
          <thead>
            <tr>
              <th class="medium">Name</th>
              <th class="large">Response within</th>
              <th class="large">Notify To</th>
              <th class="large">Escalate To</th>
              <th class="small">Initiator</th>
              <th class="action">
                <BaseActionButton
                  is-flat
                  class="q-ml-xs"
                  color="secondary"
                  icon="eva-plus-outline"
                  no-border
                  @click="addRow"
                />
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(row, index) in value.rules" :key="index">
              <td>
                <TextField
                  v-model="value.rules[index]['name']"
                  :is-clearable="true"
                />
              </td>
              <td>
                <div class="row">
                  <NumberField
                    v-model="value.rules[index]['duration']"
                    class="col-3"
                    :is-clearable="false"
                  />
                  <SelectField
                    v-model="value.rules[index]['durationType']"
                    class="col"
                    :options="durationOption"
                    :is-clearable="false"
                  />
                </div>
              </td>
              <td>
                <MultiSelectField
                  v-model="value.rules[index].settingsJson['notifyUsers']"
                  :options="userList"
                  :is-clearable="false"
                  @input="
                    value.rules[index].settingsJson['notifyUsers'].length
                      ? (value.rules[index].settingsJson['notifyAction'] = true)
                      : (value.rules[index].settingsJson[
                          'notifyAction'
                        ] = false)
                  "
                />
              </td>
              <td>
                <SingleChoiceField
                  v-model="value.rules[index].settingsJson.escalateType"
                  :options="escalateTypes"
                  :options-per-line="2"
                  class="q-mb-sm"
                  @input="updateEscalateType(index)"
                />
                <MultiSelectField
                  v-if="value.rules[index].settingsJson.escalateType === 'USER'"
                  v-model="value.rules[index].settingsJson['escalateUsers']"
                  :options="userList"
                  :is-clearable="false"
                  @input="
                    value.rules[index].settingsJson['escalateUsers'].length
                      ? (value.rules[index].settingsJson[
                          'escalateAction'
                        ] = true)
                      : (value.rules[index].settingsJson[
                          'escalateAction'
                        ] = false)
                  "
                />

                <SelectField
                  v-if="
                    value.rules[index].settingsJson.escalateType === 'STAGE'
                  "
                  v-model="value.rules[index].settingsJson['escalateStage']"
                  class="col"
                  :options="_activityList"
                  :is-clearable="false"
                />
              </td>
              <td class="custom-center">
                <CheckboxField
                  v-model="value.rules[index].settingsJson['initiator']"
                />
              </td>
              <td class="action">
                <BaseActionButton
                  is-flat
                  color="red"
                  icon="eva-close-outline"
                  no-border
                  @click="removeRow(index)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <div class="q-mt-md">
          <SelectField
            v-model="value.settings.slaMasterFormId"
            label="SLA Master"
            :is-searchable="true"
            :options="slaMasterFormList"
            class="field"
          />
          <SelectField
            v-if="value.settings.slaMasterFormId"
            v-model="value.settings.workflowFormSLAFieldId"
            label="Workflow Form - SLA Field"
            :is-searchable="true"
            :options="formFields"
            class="q-mt-md"
          />
        </div>
      </template>

      <div class="q-mt-md">
        <CheckboxField
          v-model="value.settings.emailNotify"
          label="Email Notification"
          description="To sends the email notification once the SLA crossed"
          class="field"
        />
      </div>
      <div class="q-mt-md">
        <CheckboxField
          v-model="value.settings.smsNotify"
          label="SMS Notification"
          description="To sends the sms notification once the SLA crossed"
          class="field"
        />
      </div>
    </BaseScrollbar>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import { form } from "@/api/factory.js";
export default {
  name: "SLABuilder",

  components: {
    FormFieldLabel,
    TextField,
    NumberField,
    SelectField,
    SingleChoiceField,
    MultiSelectField,
    CheckboxField,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    userList: {
      type: Array,
      default: () => [],
    },

    formId: {
      type: Number,
      default: 0,
    },

    activityList: {
      type: Array,
      default: () => [],
    },

    block: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      durationOption: [
        {
          id: this.$nano.id(),
          label: "Minutes",
          value: "minutes",
        },
        {
          id: this.$nano.id(),
          label: "Hours",
          value: "hours",
        },
        {
          id: this.$nano.id(),
          label: "Days",
          value: "days",
        },
      ],
      actions: [
        {
          id: this.$nano.id(),
          label: "Alert",
          value: "ALERT",
        },
        {
          id: this.$nano.id(),
          label: "Escalate",
          value: "ESCALATE",
        },
      ],
      slaTypes: [
        {
          id: this.$nano.id(),
          label: "Standard",
          value: "STANDARD",
        },
        {
          id: this.$nano.id(),
          label: "Advanced",
          value: "ADVANCED",
        },
      ],
      slaMasterFormList: [],
      formFields: [],
      escalateTypes: [
        {
          id: this.$nano.id(),
          label: "User",
          value: "USER",
        },
        {
          id: this.$nano.id(),
          label: "Stage",
          value: "STAGE",
        },
      ],
    };
  },

  computed: {
    _activityList() {
      return this.activityList.filter((list) => list.id !== this.block.id);
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$emit("input", this.value);
      },
    },

    formId: {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.formId) {
          this.getFormFields();
        }
      },
    },
  },

  mounted() {
    if (this.value.rules.length === 0) {
      this.addRow();
    }
  },

  created() {
    this.getSLAMasterForms();
  },

  methods: {
    addRow() {
      const model = {
        id: 0,
        name: "",
        duration: "",
        durationType: "minutes",
        settingsJson: {
          notifyAction: false,
          notifyUsers: [],
          escalateAction: false,
          escalateUsers: [],
        },
      };

      this.value.rules.push(model);
    },

    removeRow(rowIdx) {
      this.value.rules.splice(rowIdx, 1);
    },

    async getSLAMasterForms() {
      this.$store.commit("showLoadingBar");
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "SLA",
                dataType: "",
              },
              {
                criteria: "publishOption",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 500,
        currentPage: 1,
        hasSecurity: false,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.slaMasterFormList = [];
      const { data } = payload;
      if (data.length) {
        this.slaMasterFormList = data[0].value.map((form) => ({
          id: this.$nano.id(),
          label: form.name,
          value: form.id,
        }));
      }
    },

    async getFormFields() {
      this.formFields = [];
      const { error, payload } = await form.getForm(this.formId);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        let form = JSON.parse(payload.formJson);
        let masterfields = [];
        const panels = [...form.panels, ...form.secondaryPanels];

        if (!panels.length) {
          return;
        }
        for (const panel of panels) {
          masterfields.push(...panel.fields);
        }

        masterfields.forEach((field) => {
          if (field.type !== "DIVIDER") {
            this.formFields.push({
              id: field.id,
              label: field.label,
              value: field.id,
            });
          }
        });
      }
    },

    updateEscalateType(index) {
      if (this.value.rules[index].settingsJson.escalateType === "USER") {
        this.value.rules[index].settingsJson.escalateStage = "";
      } else if (
        this.value.rules[index].settingsJson.escalateType === "STAGE"
      ) {
        this.value.rules[index].settingsJson.escalateUsers = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#sla-builder {
  .option-wrapper {
    border-top: 1px solid var(--divider-color);
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th:not(.action) {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 60px;
      }

      &.medium {
        width: 100px;
      }

      &.large {
        width: 130px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      &.action {
        width: 36px;
      }
    }

    td:not(.custom-center) {
      padding: 4px;
      vertical-align: top;
    }
  }
}
</style>
<style lang="scss">
#sla-builder {
  #single-choice-field .options-wrapper {
    border-top: 0px !important;
    .option {
      padding-top: 5px !important;
    }
  }

  .action-header {
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 10px;
  }

  .custom-center {
    text-align: center;
    #checkbox-field .option {
      display: unset !important;
    }
  }
}
</style>
