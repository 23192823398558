<template>
  <div id="workflow-builder">
    <WorkflowHeader
      :workflow-settings="workflow.settings"
      :is-loading="isLoading"
      @settings="showSettings"
      @save="saveWorkflow"
    />

    <WorkflowCanvas :workflow="workflow" :workflow-id="workflowId" />
  </div>
</template>

<script>
import WorkflowHeader from "./components/WorkflowHeader.vue";
import WorkflowCanvas from "./components/workflow-canvas/WorkflowCanvas.vue";

export default {
  name: "WorkflowBuilder",

  components: { WorkflowHeader, WorkflowCanvas },

  props: {
    workflowId: {
      type: String,
      default: "",
    },
    workflow: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    showSettings() {
      this.$emit("settings");
    },

    saveWorkflow() {
      this.$emit("save");
    },
  },
};
</script>

<style lang="scss" scoped>
#workflow-builder {
  min-height: calc(100vh - 68px);
  background-color: var(--body-bg-color);
}
</style>
