<template>
  <FormSection
    :icon="isActive ? 'eva-options-2' : 'eva-options-2-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>General</template>
    <template #description>
      Name your workflow and provide detailed description for your reference.
    </template>

    <!-- name -->

    <ValidationProvider
      v-slot="{ errors }"
      name="name"
      :rules="{ required: true }"
    >
      <TextField
        :value="name"
        label="name"
        is-mandatory
        :is-readonly="mode === 'EDIT'"
        :error="errors[0]"
        class="q-mb-lg"
        @input="updateName"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- description -->

    <TextAreaField
      :value="description"
      label="description"
      class="q-mb-lg"
      @input="updateDescription"
    />

    <!-- ... -->

    <!-- initiate using -->

    <InitiateUsing
      :value="initiateUsing"
      :ocr-credit="ocrCredit"
      class="q-mb-lg"
      @input="updateInitiateUsing"
      @credit="updateCredit"
    />

    <!-- ... -->

    <!-- prefix -->

    <!-- <ValidationProvider
      v-slot="{ errors }"
      name="prefix"
      :rules="{ required: true }"
    >
      <TextField
        :value="prefix"
        label="process number prefix"
        is-mandatory
        :error="errors[0]"
        class="q-mb-lg"
        @input="updatePrefix"
      />
    </ValidationProvider> -->

    <FormFieldLabel label="process number prefix" is-mandatory />

    <table class="q-mt-md">
      <thead>
        <tr>
          <th class="medium">Prefix Name</th>
          <th class="large">Value</th>
          <!-- <th class="large">Notify Users</th> -->
          <th class="action">
            <BaseActionButton
              is-flat
              class="q-ml-xs"
              color="secondary"
              icon="eva-plus-outline"
              no-border
              @click="addRow"
            />
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(row, index) in prefix" :key="index">
          <!-- <td>
            <SelectField
              v-model="row.key"
              class="col"
              :options="prefixList"
              :is-clearable="false"
              :is-readonly="row.key === 'seperator' || row.key === 'reset'"
            />
          </td> -->
          <td>
            <template v-if="row.key === 'seperator'">
              <TextField
                v-model="row.key"
                class="col text-capitalize"
                :is-clearable="true"
                :is-readonly="row.key === 'seperator'"
              />
            </template>
            <template v-if="row.key === 'reset'">
              <TextField
                v-model="row.key"
                class="col text-capitalize"
                :is-clearable="true"
                :is-readonly="row.key === 'reset'"
              />
            </template>
            <template v-if="row.key !== 'seperator' && row.key !== 'reset'">
              <ValidationProvider
                v-slot="{ errors }"
                :name="row.key"
                class="col-12"
                :rules="{ required: true }"
              >
                <SelectField
                  v-model="row.key"
                  class="col"
                  :options="prefixList"
                  is-searchable
                  :error="errors[0]"
                  @input="selectedKey"
                />
              </ValidationProvider>
            </template>
          </td>
          <td>
            <div class="row items-center">
              <template v-if="row.key === 'prefix'">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="row.key"
                  class="col-12"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="row.value"
                    class="col"
                    :is-clearable="true"
                    :error="errors[0]"
                    :placeholder="'enter the prefix'"
                  />
                </ValidationProvider>
              </template>
              <template v-if="row.key === 'autoIncrement'">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="row.key"
                  class="col-12"
                  :rules="validationRules"
                >
                  <CounterField
                    v-model="row.value"
                    class="col"
                    :is-clearable="false"
                    :placeholder="'enter no.of digit'"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </template>
              <template v-if="row.key === 'year'">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="row.key"
                  class="col-12"
                  :rules="{ required: true }"
                >
                  <SelectField
                    v-model="row.value"
                    class="col"
                    :options="years"
                    is-searchable
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </template>
              <template v-if="row.key === 'month'">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="row.key"
                  class="col-12"
                  :rules="{ required: true }"
                >
                  <SelectField
                    v-model="row.value"
                    class="col"
                    :options="months"
                    is-searchable
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </template>
              <template v-if="row.key === 'formColumn'">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="row.key"
                  class="col-12"
                  :rules="{ required: true }"
                >
                  <MultiSelectField
                    v-model="row.value"
                    :options="formColumns"
                    is-searchable
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </template>
              <template v-if="row.key === 'seperator'">
                <SelectField
                  v-model="row.value"
                  class="col"
                  :options="seperator"
                  is-searchable
                />
              </template>
              <template v-if="row.key === 'reset'">
                <SelectField
                  v-model="row.value"
                  class="col"
                  :options="reset"
                  is-searchable
                />
              </template>
              <template v-if="row.key === 'currentDate'">
                <div class="col-12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="row.key"
                    :rules="{ required: true }"
                  >
                    <SelectField
                      v-model="row.value"
                      class="col-12"
                      :options="currentDates"
                      is-searchable
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </template>
            </div>
          </td>

          <td class="action">
            <BaseActionButton
              v-if="row.key !== 'seperator' && row.key !== 'reset'"
              is-flat
              color="red"
              icon="eva-close-outline"
              no-border
              @click="removeRow(index)"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="format row">
      <div class="col-auto q-mr-sm">Format :</div>
      <div v-for="(row, index) in prefix" :key="index" class="row">
        {{ getFormat(row, index) }}
      </div>
    </div>

    <!-- ... -->

    <!-- Link Master Form -->

    <SelectField
      :value="linkMasterFormId"
      label="Master Form Link"
      :options="masterFormList"
      class="q-mt-lg"
      @input="updateLinkMasterFormId"
    />

    <!-- ... -->

    <!--  Co-Ordinator -->

    <MultiSelectField
      :value="coordinator"
      label="Co-Ordinator"
      :options="userList"
      class="q-mt-lg"
      @input="updateCoordinator"
    />

    <!-- ... -->

    <!--  Superuser -->

    <MultiSelectField
      :value="superuser"
      label="Superuser"
      :options="userList"
      class="q-mt-lg"
      @input="updateSuperuser"
    />

    <!-- ... -->
    <!--  smtp -->
    <SelectField
      :value="smtpEmailId"
      label="Choose SMTP name to push the notification email"
      :options="smtpList"
      class="q-mt-lg"
      @input="updateSmtpEmailId"
    />
  </FormSection>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FormSection from "@/components/common/item-builder/FormSection.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import InitiateUsing from "./components/InitiateUsing.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import { form, integration } from "@/api/factory.js";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CounterField from "@/components/common/form/counter-field/CounterField.vue";

export default {
  name: "GeneralSettings",

  components: {
    FormSection,
    ValidationProvider,
    TextField,
    TextAreaField,
    InitiateUsing,
    SelectField,
    MultiSelectField,
    FormFieldLabel,
    CounterField,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    initiateUsing: {
      type: Object,
      required: true,
    },

    // prefix: {
    //   type: String,
    //   default: "REQ-",
    // },

    prefix: {
      type: Array,
      default: () => [],
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    linkMasterFormId: {
      type: Number,
      default: 0,
    },

    smtpEmailId: {
      type: Number,
      default: 0,
    },

    userList: {
      type: Array,
      default: () => [],
    },

    coordinator: {
      type: Array,
      default: () => [],
    },

    superuser: {
      type: Array,
      default: () => [],
    },

    ocrCredit: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      masterFormList: [],
      smtpList: [],
      chips: ["file"],
      isFocused: false,
      newChip: "",
      optionPicker: false,
      prefixList: [
        {
          id: this.$nano.id(),
          label: "Prefix",
          value: "prefix",
        },
        {
          id: this.$nano.id(),
          label: "Year",
          value: "year",
        },
        {
          id: this.$nano.id(),
          label: "Month",
          value: "month",
        },
        {
          id: this.$nano.id(),
          label: "Form Column",
          value: "formColumn",
        },
        {
          id: this.$nano.id(),
          label: "Auto Increment",
          value: "autoIncrement",
        },
        {
          id: this.$nano.id(),
          label: "Current Date",
          value: "currentDate",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Seperator",
        //   value: "seperator",
        // },
        // {
        //   id: this.$nano.id(),
        //   label: "Reset",
        //   value: "reset",
        // },
      ],
      years: [
        {
          id: this.$nano.id(),
          label: "YYYY",
          value: "yyyy",
        },
        {
          id: this.$nano.id(),
          label: "YY",
          value: "yy",
        },
      ],
      months: [
        {
          id: this.$nano.id(),
          label: "MMMM",
          value: "mmmm",
        },
        {
          id: this.$nano.id(),
          label: "MMM",
          value: "mmm",
        },
        {
          id: this.$nano.id(),
          label: "MM",
          value: "mm",
        },
      ],
      formColumns: [],
      seperator: [
        {
          id: this.$nano.id(),
          label: "-",
          value: "-",
        },
        {
          id: this.$nano.id(),
          label: "#",
          value: "#",
        },
        {
          id: this.$nano.id(),
          label: "_",
          value: "_",
        },
      ],
      reset: [
        {
          id: this.$nano.id(),
          label: "Year",
          value: "year",
        },
        {
          id: this.$nano.id(),
          label: "Month",
          value: "month",
        },
      ],
      currentIndex: 0,
      currentDates: [
        {
          id: this.$nano.id(),
          label: "DDMMYY",
          value: "ddMMyy",
        },
        {
          id: this.$nano.id(),
          label: "DDMMYYYY",
          value: "ddMMyyyy",
        },
        {
          id: this.$nano.id(),
          label: "DDMMMYY",
          value: "ddMMMyy",
        },
        {
          id: this.$nano.id(),
          label: "DDMMMYYYY",
          value: "ddMMMyyyy",
        },
        {
          id: this.$nano.id(),
          label: "DDMMMMYY",
          value: "ddMMMMyy",
        },
        {
          id: this.$nano.id(),
          label: "DDMMMMYYYY",
          value: "ddMMMMyyyy",
        },
        {
          id: this.$nano.id(),
          label: "MMDDYY",
          value: "MMddyy",
        },
        {
          id: this.$nano.id(),
          label: "MMDDYYYY",
          value: "MMddyyyy",
        },
        {
          id: this.$nano.id(),
          label: "MMMDDYY",
          value: "MMMddyy",
        },
        {
          id: this.$nano.id(),
          label: "MMMDDYYYY",
          value: "MMMddyyyy",
        },
        {
          id: this.$nano.id(),
          label: "MMMMDDYY",
          value: "MMMMddyy",
        },
        {
          id: this.$nano.id(),
          label: "MMMMDDYYYY",
          value: "MMMMddyyyy",
        },
        {
          id: this.$nano.id(),
          label: "YYMMDD",
          value: "yyMMdd",
        },
        {
          id: this.$nano.id(),
          label: "YYYYMMDD",
          value: "yyyyMMdd",
        },
        {
          id: this.$nano.id(),
          label: "YYMMMDD",
          value: "yyMMMdd",
        },
        {
          id: this.$nano.id(),
          label: "YYYYMMMDD",
          value: "yyyyMMMdd",
        },
        {
          id: this.$nano.id(),
          label: "YYMMMMDD",
          value: "yyMMMMdd",
        },
        {
          id: this.$nano.id(),
          label: "YYYYMMMMDD",
          value: "yyyyMMMMdd",
        },
      ],
    };
  },

  computed: {
    validationRules() {
      const rules = {
        integer: true,
        min_value: 1,
        max_value: 7,
        required: true,
      };

      return rules;
    },
  },

  watch: {
    initiateUsing: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.initiateUsing.formId) {
          await this.getFormColumns();
        }
      },
    },

    prefix: {
      deep: true,
      // immediate: true,
      async handler() {
        this.$emit("update:prefix", this.prefix);
      },
    },
  },

  created() {
    this.getMasterForms();
    this.getSMTPConnection();
  },

  methods: {
    updateName(name) {
      this.$emit("update:name", name);
    },

    updateDescription(description) {
      this.$emit("update:description", description);
    },

    updateInitiateUsing(initiateUsing) {
      this.$emit("update:initiateUsing", initiateUsing);
    },

    updatePrefix(prefix) {
      this.$emit("update:prefix", prefix);
    },

    updateLinkMasterFormId(value) {
      this.$emit("update:linkMasterFormId", value);
    },

    updateSmtpEmailId(value) {
      this.$emit("update:smtpEmailId", value);
    },

    updateCoordinator(value) {
      this.$emit("update:coordinator", value);
    },

    updateSuperuser(value) {
      this.$emit("update:superuser", value);
    },

    updateCredit(value, required) {
      this.$emit("credit", value, required);
    },

    async getMasterForms() {
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "MASTER",
                dataType: "",
              },
              {
                criteria: "publishOption",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 500,
        currentPage: 1,
        hasSecurity: false,
      });

      if (error) {
        this.$alert.error("Error fetching master form list");
        return;
      }

      this.masterFormList = [];
      const { data } = payload;
      if (data.length) {
        this.masterFormList = data[0].value.map((form) => ({
          id: this.$nano.id(),
          label: form.name,
          value: form.id,
        }));
      }
    },

    async getSMTPConnection() {
      const { error, payload } = await integration.getSMTPConnection({
        filterBy: [],
      });
      if (payload.length) {
        this.smtpList =
          payload &&
          payload.map((name) => ({
            id: this.$nano.id(),
            label: name.settingName,
            value: name.settingId,
          }));
      }
      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    removeRow(optionIdx) {
      this.prefix.splice(optionIdx, 1);
    },

    addRow() {
      this.prefix.push({
        id: this.$nano.id(),
        key: "",
        value: [],
      });
      this.currentIndex = this.prefix.length - 1;
    },

    selectedKey() {
      if (this.prefix[this.currentIndex].key === "prefix") {
        this.prefix[this.currentIndex].value = "REQ";
      }

      if (this.prefix[this.currentIndex].key === "autoIncrement") {
        this.prefix[this.currentIndex].value = 1;
      }
    },

    async getFormColumns() {
      this.formColumns = [];
      if (this.initiateUsing.formId) {
        const { error, payload } = await form.getForm(
          this.initiateUsing.formId
        );

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload) {
          let form = JSON.parse(payload.formJson);
          let fields = [];
          const panels = [...form.panels, ...form.secondaryPanels];

          if (!panels.length) {
            return;
          }
          // let panelIndex = 1;
          for (const panel of panels) {
            if (panel.fields.length) {
              fields.push(...panel.fields);
            }
          }

          fields.forEach((field) => {
            if (field.type !== "DIVIDER") {
              this.formColumns.push({
                id: field.id,
                label: field.label ? field.label : field.type,
                value: field.id,
              });
            }
          });
        }
      }
    },

    getFormat(data, index) {
      let string = "";
      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var monthShort = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var date = new Date();

      if (data.key === "prefix") {
        if (data.value) {
          string += `${data.value}${this.prefix[0].value}`;
        }
      } else if (data.key === "formColumn") {
        if (data.value.length) {
          var form = [];
          data.value.forEach((col) => {
            if (col) {
              let column = this.formColumns.find((item) => item.value === col);
              if (column) {
                form.push(column.label);
              }
            }
          });
          if (form.length)
            string += `${form.toString()}${this.prefix[0].value}`;
        }
      } else if (data.key === "year") {
        if (data.value) {
          if (data.value === "yy") {
            string += `${new Date().getFullYear().toString().substr(-2)}${
              this.prefix[0].value
            }`;
          } else if (data.value === "yyyy") {
            string += `${new Date().getFullYear()}${this.prefix[0].value}`;
          }
        }
      } else if (data.key === "month") {
        if (data.value) {
          if (data.value === "mm") {
            string += `${(new Date().getMonth() + 1)
              .toString()
              .padStart(2, "0")}${this.prefix[0].value}`;
          } else if (data.value === "mmm") {
            string += `${monthShort[new Date().getMonth()]}${
              this.prefix[0].value
            }`;
          } else if (data.value === "mmmm") {
            string += `${month[new Date().getMonth()]}${this.prefix[0].value}`;
          }
        }
      } else if (data.key === "autoIncrement") {
        if (data.value) {
          if (Number(data.value) <= 7) {
            var digit = "";
            for (let i = 1; i < data.value; i++) {
              digit += "0";
            }
            digit += "1";
            string += `${digit}${this.prefix[0].value}`;
          }
        }
      } else if (data.key === "currentDate") {
        let day = (date.getDate() > 9 ? "" : "0") + date.getDate();
        let monthNum =
          (date.getMonth() + 1 > 9 ? "" : "0") + (date.getMonth() + 1);
        let year = date.getFullYear();
        switch (data.value) {
          case "ddMMyy":
            string += `${day}${monthNum}${year.toString().substring(2)}`;
            break;
          case "ddMMyyyy":
            string += `${day}${monthNum}${year}`;
            break;
          case "ddMMMyy":
            string += `${day}${monthShort[date.getMonth()]}${year
              .toString()
              .substring(2)}`;
            break;
          case "ddMMMyyyy":
            string += `${day}${monthShort[date.getMonth()]}${year}`;
            break;
          case "ddMMMMyy":
            string += `${day}${month[date.getMonth()]}${year
              .toString()
              .substring(2)}`;
            break;
          case "ddMMMMyyyy":
            string += `${day}${month[date.getMonth()]}${year}`;
            break;
          case "MMddyy":
            string += `${monthNum}${day}${year.toString().substring(2)}`;
            break;
          case "MMddyyyy":
            string += `${monthNum}${day}${year}`;
            break;
          case "MMMddyy":
            string += `${monthShort[date.getMonth()]}${day}${year
              .toString()
              .substring(2)}`;
            break;
          case "MMMddyyyy":
            string += `${monthShort[date.getMonth()]}${day}${year}`;
            break;
          case "MMMMddyy":
            string += `${month[date.getMonth()]}${day}${year
              .toString()
              .substring(2)}`;
            break;
          case "MMMMddyyyy":
            string += `${month[date.getMonth()]}${day}${year}`;
            break;
          case "yyMMdd":
            string += `${year.toString().substring(2)}${monthNum}${day}`;
            break;
          case "yyyyMMdd":
            string += `${year}${monthNum}${day}`;
            break;
          case "yyMMMdd":
            string += `${year.toString().substring(2)}${
              monthShort[date.getMonth()]
            }${day}`;
            break;
          case "yyyyMMMdd":
            string += `${year}${monthShort[date.getMonth()]}${day}`;
            break;
          case "yyMMMMdd":
            string += `${year.toString().substring(2)}${
              month[date.getMonth()]
            }${day}`;
            break;
          case "yyyyMMMMdd":
            string += `${year}${month[date.getMonth()]}${day}`;
            break;
        }
        string += this.prefix[0].value;
      }
      let last = string.slice(-1);

      var format = "";
      if (index + 1 === this.prefix.length) {
        if (last === this.prefix[0].value) {
          format = string.substring(0, string.length - 1);
          // console.log(format);
        }
      } else {
        format += string;
      }
      return `${format}`;
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  tr {
    height: 48px;
  }

  th:not(.action) {
    padding: 4px 8px;
    text-align: left;
    font-weight: 500;
    text-transform: capitalize;

    &.medium {
      width: 120px;
    }

    &.large {
      width: 140px;
    }
  }

  th,
  td {
    border: 1px solid var(--divider-color);
    &.action {
      width: 36px;
    }
  }

  td {
    padding: 4px;
    vertical-align: top;
  }
}

.format {
  @extend .text-base;
  color: var(--primary);
  margin-top: 4px;
  font-weight: bold;
}
</style>
