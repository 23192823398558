<template>
  <div id="workflow-canvas">
    <BaseScrollbar height="calc(100vh - 124px)">
      <!-- canvas -->

      <canvas id="canvas" :height="canvasHeight" :width="canvasWidth"></canvas>

      <!-- ... -->

      <!-- zoom and view-->

      <div class="zoom">
        <!-- plain view-->
        <BaseActionButton
          v-tooltip:secondary.left="'plain view'"
          icon="mdi-card-outline"
          no-border
          color="secondary"
          class="q-mb-sm"
          @click="updateActiveView('PLAIN')"
        />
        <!-- ... -->

        <!-- line view-->
        <BaseActionButton
          v-tooltip:secondary.left="'line view'"
          icon="mdi-view-module-outline"
          no-border
          color="secondary"
          class="q-mb-sm"
          @click="updateActiveView('LINE')"
        />
        <!-- ... -->

        <!-- zoom in -->

        <BaseActionButton
          v-tooltip:secondary.left="'zoom in'"
          icon="eva-maximize-outline"
          no-border
          color="secondary"
          class="q-mb-sm"
          @click="zoomIn"
        />

        <!-- ... -->

        <!-- zoom out -->

        <BaseActionButton
          v-tooltip:secondary.left="'zoom out'"
          icon="eva-minimize-outline"
          no-border
          color="secondary"
          class="q-mb-sm"
          @click="zoomOut"
        />

        <!-- ... -->

        <!-- reset -->

        <BaseActionButton
          v-tooltip:primary.left="'reset'"
          icon="mdi-crop-free"
          no-border
          color="primary"
          @click="zoomReset"
        />

        <!-- ... -->
      </div>

      <!-- ... -->

      <!-- history -->

      <div class="history">
        <!-- undo -->

        <BaseActionButton
          v-tooltip:secondary.top="'undo'"
          icon="eva-refresh"
          no-border
          color="secondary"
          class="q-mr-sm flip-horizontal"
          @click="undo"
        />

        <!-- ... -->

        <!-- redo -->

        <BaseActionButton
          v-tooltip:secondary.top="'redo'"
          icon="eva-refresh"
          no-border
          color="secondary"
          @click="redo"
        />

        <!-- ... -->
      </div>

      <!-- ... -->
    </BaseScrollbar>

    <!-- add blocks modal -->

    <AddBlocksModal
      v-model="isAddBlocksModalVisible"
      :blocks="blocks"
      @addBlock="addBlock"
    />

    <!-- ... -->

    <!-- connect blocks modal -->

    <ConnectBlocksModal
      v-model="isConnectBlocksModalVisible"
      :blocks="blocks"
      :active-block-id="activeBlockId"
      @connectBlock="connectBlock"
    />

    <!-- ... -->

    <!-- block settings -->

    <BlockSettings
      v-model="isBlockSettingsVisible"
      :workflow="workflow"
      :workflow-id="workflowId"
      :block="activeBlock"
      :blocks="blocks"
      :rules="connections"
      @save="saveBlock"
      @removeConnection="removeConnection"
    />

    <!-- ... -->
  </div>
</template>

<script>
import { fabric } from "fabric";
import AddBlocksModal from "./components/AddBlocksModal.vue";
import ConnectBlocksModal from "./components/ConnectBlocksModal.vue";
import BlockSettings from "./components/block-settings/BlockSettings.vue";

import canvasMixin from "./mixins/canvas-mixin.js";
import alignmentGuideLineMixin from "./mixins/alignment-guide-line-mixin.js";
import laneMixin from "./mixins/lane-mixin.js";
import blockMixin from "./mixins/block-mixin.js";
import connectionMixin from "./mixins/connection-mixin.js";

export default {
  name: "WorkflowCanvas",

  components: { AddBlocksModal, ConnectBlocksModal, BlockSettings },

  mixins: [
    canvasMixin,
    alignmentGuideLineMixin,
    blockMixin,
    laneMixin,
    connectionMixin,
  ],

  props: {
    workflowId: {
      type: String,
      default: "",
    },
    workflow: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      canvas: null,
      blocks: [],
      isAddBlocksModalVisible: false,
      isConnectBlocksModalVisible: false,
      activeBlockId: "",
      activeConnectionId: "",
      isBlockSettingsVisible: false,
      activeView: "LINE",
      connections: [],
    };
  },

  computed: {
    canvasWidth() {
      return this.laneWidth * 20 + this.laneHeaderHeight * 2 + "px";
    },

    canvasHeight() {
      return this.laneHeight * 20 + this.laneHeaderHeight * 2 + "px";
    },

    activeBlock() {
      if (!this.activeBlockId) return null;
      return this.blocks.find((block) => block.id === this.activeBlockId);
    },

    // connections() {
    //   //return [];
    // },
  },

  watch: {
    workflow: {
      immediate: true,
      handler() {
        if (this.workflow.blocks.length) {
          this.renderWorkflow();
        }
      },
    },

    blocks: {
      immediate: true,
      handler() {
        this.workflow.blocks = this.blocks;
      },
    },

    connections: {
      immediate: true,
      handler() {
        this.workflow.rules = this.connections;
      },
    },
  },

  mounted() {
    this.canvas = new fabric.Canvas("canvas", { selection: false });
    this.initAligningGuidelines(this.canvas);
    // handle events
    this.canvas.on({
      "object:moving": this.handleBlockMove,
      "mouse:down": this.handleCanvasClick,
    });
    // add vLanes
    const vLanes = this.createVLanes();
    this.canvas.add(...vLanes);
    // add hLanes
    const hLanes = this.createHLanes();
    this.canvas.add(...hLanes);
    if (this.workflowId === "") {
      // add start block
      this.addBlock("START", 54, 54);

      // add end block
      this.addBlock("END", 915, 430);
    }
  },

  methods: {
    renderWorkflow() {
      this.blocks = [];
      this.workflow.blocks.forEach((block) => {
        this.addBlock(
          block.type,
          block.left,
          block.top,
          block.id,
          block.settings.label
        );
        this.blocks.push(block);
      });
      this.workflow.rules.forEach((rule) => {
        let fromBlock = this.blocks.find(
          (block) => block.id === rule.fromBlockId
        );
        let toBlock = this.blocks.find((block) => block.id === rule.toBlockId);
        let ruleConnection = this.createConnection(fromBlock, toBlock, rule.id);
        this.canvas.add(ruleConnection);
        if (!rule["remarks"]) {
          rule.remarks = false;
        }
        if (!rule["passwordAccess"]) {
          rule.passwordAccess = false;
        }
        if (!rule["confirm"]) {
          rule.confirm = false;
        }
        this.connections.push(rule);
      });
    },

    zoomIn() {
      const zoomValue = this.canvas.getZoom();

      if (zoomValue < 1.75) {
        this.canvas.setZoom(zoomValue + 0.25);
      }
    },

    zoomOut() {
      const zoomValue = this.canvas.getZoom();

      if (zoomValue > 0.25) {
        this.canvas.setZoom(zoomValue - 0.25);
      }
    },

    zoomReset() {
      this.canvas.setZoom(1);
    },

    undo() {},

    redo() {},

    handleBlockMove(e) {
      const { type, id, left, top } = e.target;
      if (type !== "block") return;

      const blockIdx = this.blocks.findIndex((block) => block.id === id);
      this.blocks[blockIdx].left = left;
      this.blocks[blockIdx].top = top;

      this.updateConnections(id);
    },

    handleCanvasClick(e) {
      if (e.target.type === "block") this.handleBlockClick(e);
      else if (e.target.type === "connection") console.log("connection");
      else this.deselectAll();
    },

    removeConnection(rule) {
      this.deleteConnection(rule.id);
    },

    saveBlock(block) {
      if (this.activeBlockId) {
        const blockIdx = this.blocks.findIndex(
          (block) => block.id === this.activeBlockId
        );
        this.blocks[blockIdx] = block;
      }
      this.activeBlockId = "";
      // this.canvas._objects.forEach((_object) => {
      //   if (_object.type === "block" && _object.id === block.id) {
      //     _object._objects[2]._objects[1].text = block.settings.label;
      //   }
      // });
      //this.canvas.renderAll();
      //this.canvas.requestRenderAll();
    },

    updateActiveView(view) {
      this.activeView = view;
      var objects = this.canvas.getObjects("group");
      for (let i in objects) {
        this.canvas.remove(objects[i]);
      }
      // handle events
      this.canvas.on({
        "object:moving": this.handleBlockMove,
        "mouse:down": this.handleCanvasClick,
      });
      if (view === "LINE") {
        // add vLanes
        const vLanes = this.createVLanes();
        this.canvas.add(...vLanes);

        // add hLanes
        const hLanes = this.createHLanes();
        this.canvas.add(...hLanes);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#workflow-canvas {
  .zoom,
  .view {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 16px;
    z-index: 1;
  }

  .history {
    position: fixed;
    bottom: 0;
    right: 60px;
    margin-bottom: 16px;
    z-index: 1;
    display: flex;
  }
}
</style>
