<template>
  <BaseActionButton v-tooltip.top="'add field'" is-flat icon="eva-plus">
    <q-menu
      anchor="bottom middle"
      self="top middle"
      transition-show="scale"
      transition-hide="scale"
    >
      <div class="row border">
        <div class="title-3 q-pa-xs col-auto">
          <input
            ref="searchQuery"
            v-model="searchColumns"
            type="text"
            class="q-pa-md"
            placeholder="Search"
            autofocus
            @keyup="searchColumnList(searchColumns)"
          />
        </div>
      </div>
      <BaseScrollbar height="200px">
        <ListItem
          v-for="field in fields"
          :key="field.id"
          :label="field.label"
          :color="field.color"
          class="text-capitalize"
          :class="field.display"
          @click="$emit('click', field)"
        />
      </BaseScrollbar>
    </q-menu>
  </BaseActionButton>
</template>

<script>
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "AddField",

  components: { ListItem },

  props: {
    formColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        {
          id: this.$nano.id(),
          label: "request no",
          value: "#REQUESTNO#",
          display: "",
          color: "#643094",
        },
        {
          id: this.$nano.id(),
          label: "workflow name",
          value: "#WORKFLOWNAME#",
          display: "",
          color: "#643094",
        },
        {
          id: this.$nano.id(),
          label: "stage",
          value: "#STAGE#",
          display: "",
          color: "#643094",
        },
        {
          id: this.$nano.id(),
          label: "sender name",
          value: "#SENDERNAME#",
          display: "",
          color: "#643094",
        },
        {
          id: this.$nano.id(),
          label: "last action stage",
          value: "#LASTACTIONSTAGE#",
          display: "",
          color: "#643094",
        },
        {
          id: this.$nano.id(),
          label: "last acted by",
          value: "#LASTACTEDBY#",
          display: "",
          color: "#643094",
        },
        {
          id: this.$nano.id(),
          label: "last acted on",
          value: "#LASTACTEDON#",
          display: "",
          color: "#643094",
        },
        {
          id: this.$nano.id(),
          label: "raised by",
          value: "#RAISEDBY#",
          display: "",
          color: "#643094",
        },
        {
          id: this.$nano.id(),
          label: "raised on",
          value: "#RAISEDON#",
          display: "",
          color: "#643094",
        },
        {
          id: this.$nano.id(),
          label: "form link",
          value: "#FORMLINK#",
          display: "",
          color: "#643094",
        },
        {
          id: this.$nano.id(),
          label: "history link",
          value: "#HISTORYLINK#",
          display: "",
          color: "#643094",
        },
        {
          id: this.$nano.id(),
          label: "portal link",
          value: "#PORTALLINK#",
          display: "",
          color: "#643094",
        },
        {
          id: this.$nano.id(),
          label: "all comments",
          value: "#ALLCOMMENTS#",
          display: "",
          color: "#643094",
        },
        {
          id: this.$nano.id(),
          label: "last comments",
          value: "#LASTCOMMENTS#",
          display: "",
          color: "#643094",
        },
      ],
      searchColumns: "",
    };
  },

  watch: {
    formColumns: {
      immediate: true,
      handler() {
        if (this.formColumns.length) {
          this.formColumns.forEach((column) => {
            this.fields.push({
              id: this.$nano.id(),
              label: column.label,
              value: `#F#${column.label}#F#`,
              display: "",
              color: "#00bcd4",
            });
          });
        }
      },
    },
  },
  methods: {
    searchColumnList(value) {
      this.fields.forEach((field) => {
        field.display = "";
        if (value) {
          if (!field.label.toLowerCase().includes(value.toLowerCase())) {
            field.display = "hide";
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.q-menu .border {
  border: 1px solid var(--divider-color) !important;
}
#list-item.hide {
  display: none !important;
}
</style>
